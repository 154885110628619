<template>
  <div>
    <div class="row mt-5" v-if="shipment.tracking_no != null && shipment.tracking_no != '' && IsLoaded
      ">
      <div class="col-md-8 offset-md-2">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="brand-logo">
              <img src="https://api.indoboxasia.com/images/inba_logo.png" width="170" height="80" alt="Logo" />
            </div>
            <div class="card-title">
              <h1 class="card-label font-weight-bold">
                Tracking Number : {{ shipment.tracking_no }}
                <!-- <span class="text-success">{{ currentStatus }}</span> -->
              </h1>
            </div>
            <div class="card-toolbar">
              <router-link to="/track">
                <a class="text-info"><i class="flaticon2-left-arrow-1 text-info"></i> Back</a></router-link>
            </div>
          </div>
          <div class="card-body body-fluid">
            <h1 class="text-capitalize text-center" style="font-weight: 200 !important">
              {{ currentStatus }}
            </h1>
            <h2 class="text-center" style="font-weight: 200 !important">
              {{ currentStatusTime }}
            </h2>
            <v-container style="
                max-width: 600px !important;
                max-height: 300px !important;
                overflow: auto;
              ">
              <v-timeline dense clipped>
                <v-slide-x-transition group>
                  <span v-for="(event, index) in shipment.shipmentstatuses" :key="index">
                    <v-timeline-item v-if="index == 0" class="mb-4" small>
                      <v-row justify="space-between">
                        <v-col cols="7">
                          <span class="text-muted"> {{ event.location }} </span><br />
                          <strong>{{ event.status }}</strong><br />
                          {{ event.remarks }}<br />
                          {{
                            new Date(event.datetime).toLocaleString("en-SG", {
                              timeZone: 'Asia/Singapore',
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                            })
                          }}
                        </v-col>
                        <v-col class="text-right" cols="5"> </v-col>
                        <!-- </v-col>
                      <v-col
                        class="text-right"
                        cols="5"
                        v-text="event.time"
                      ></v-col> -->
                      </v-row>
                    </v-timeline-item>
                    <v-timeline-item v-if="index > 0" class="mb-4" color="grey" small>
                      <v-row justify="space-between">
                        <v-col cols="7">
                          <span class="text-muted"> {{ event.location }} </span><br />
                          <strong>{{ event.status }}</strong><br />
                          {{ event.remarks }}<br />
                          {{
                            new Date(event.datetime).toLocaleString("en-SG", {
                              timeZone: 'Asia/Singapore',
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                            })
                          }}
                        </v-col>
                        <v-col class="text-right" cols="5"> </v-col>
                        <!-- </v-col>
                      <v-col
                        class="text-right"
                        cols="5"
                        v-text="event.time"
                      ></v-col> -->
                      </v-row>
                    </v-timeline-item>
                  </span>
                </v-slide-x-transition>

                <!-- <v-timeline-item class="mb-6" hide-dot>
                  <span>TODAY</span>
                </v-timeline-item> -->

                <!-- <v-timeline-item class="mb-4" small>
                  <v-row justify="space-between">
                    <v-col cols="7">
                      <v-chip
                        class="white--text ml-0"
                        color="purple"
                        label
                        small
                      >
                        APP
                      </v-chip>
                      Digital Downloads fulfilled 1 item.
                    </v-col>
                    <v-col class="text-right" cols="5">15:25 EDT</v-col>
                  </v-row>
                </v-timeline-item>

                <v-timeline-item color="grey" small>
                  <v-row justify="space-between">
                    <v-col cols="7">
                      <span class="text-muted">
                          Kuala Lumpur
                      </span><br>
                      <strong>Pickup Request</strong><br>
                    We have received your request and our team will send you update shortly.
                    </v-col>
                    <v-col class="text-right" cols="5">
                          <a class="mr-2"> <i class="flaticon2-edit icon-sm"></i> Edit </a>
                          <a class="n"> <i class="flaticon2-cross icon-sm"></i> Delete </a>
                    </v-col>
                  </v-row>
                </v-timeline-item> -->
              </v-timeline>
            </v-container>
            <v-tabs background-color="white" color="blue accent-4">
              <v-tab>Shipment Facts</v-tab>
              <v-tab>Travel History</v-tab>

              <v-tab-item>
                <v-container fluid>
                  <div class="row">
                    <div v-if="shipment.type == 1" class="col-md-4 mt-3">
                      <h6 class="text-capitalize" style="font-size: 15px; margin-top: 15px">
                        Origin
                      </h6>
                      <br />
                      {{ shipment.virtual_address.city_state }},
                      {{ shipment.virtual_address.country }}
                    </div>
                    <div v-if="shipment.type == 2" class="col-md-4 mt-3">
                      <h6 class="text-capitalize" style="font-size: 15px; margin-top: 15px">
                        Origin
                      </h6>
                      <br />
                      {{ shipment.sender_state }},
                      {{ shipment.sender_country }}
                    </div>
                    <div class="col-md-4 mt-3">
                      <h6 class="text-capitalize" style="font-size: 15px; margin-top: 15px">
                        Destination
                      </h6>
                      <br />
                      {{ shipment.receiver_state }},
                      {{ shipment.receiver_country }}
                    </div>
                    <div class="col-md-4 mt-3">
                      <h6 class="text-capitalize" style="font-size: 15px; margin-top: 15px">
                        Tracking Number
                      </h6>
                      <br />
                      {{ shipment.tracking_no }}
                    </div>
                    <div class="col-md-4 mt-3">
                      <h6 class="text-capitalize" style="font-size: 15px; margin-top: 15px">
                        Service
                      </h6>
                      <br />
                      {{ shipment.shipping_mode }}
                    </div>
                    <div class="col-md-4 mt-3">
                      <h6 class="text-capitalize" style="font-size: 15px; margin-top: 15px">
                        Weight
                      </h6>
                      <br />
                      {{ shipment.total_weight
                      }}<span v-if="shipment.total_weight == null ||
  shipment.total_weight == ''
  ">0</span>
                      Kgs
                    </div>
                    <div class="col-md-4 mt-3">
                      <h6 class="text-capitalize" style="font-size: 15px; margin-top: 15px">
                        Dimensions
                      </h6>
                      <br />
                    </div>
                    <div class="col-md-4 mt-3">
                      <h6 class="text-capitalize" style="font-size: 15px; margin-top: 15px">
                        Packaging
                      </h6>
                      <br />
                      {{ shipment.shipment_type }}
                    </div>
                    <div class="col-md-4 mt-3">
                      <h6 class="text-capitalize" style="font-size: 15px; margin-top: 15px">
                        Ship date
                      </h6>
                      <br />
                      {{ new Date(shipment.created_at).toLocaleDateString() }}
                    </div>
                    <div class="col-md-4 mt-3">
                      <h6 class="text-capitalize" style="font-size: 15px; margin-top: 15px">
                        Delivery Date
                      </h6>
                      <br />
                    </div>
                  </div>
                </v-container>
              </v-tab-item>
              <v-tab-item>
                <v-container fluid>
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr v-for="(event, index) in shipment.shipmentstatuses" :key="index">
                          <td>
                            {{
                              new Date(event.datetime).toLocaleString("en-SG", {
                                timeZone: 'Asia/Singapore',
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                              })
                            }}
                          </td>
                          <td>{{ event.location }}</td>
                          <td>{{ event.status }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-container>
              </v-tab-item>
            </v-tabs>
          </div>
        </div>
      </div>
    </div>
    <div v-if="shipment.tracking_no == null && IsLoaded" class="row mt-5">
      <div class="col-md-6 offset-md-3">
        <div class="card">
          <div class="card-header">
            <div class="card-toolbar">
              <router-link to="/track">
                <a class="text-info"><i class="flaticon2-left-arrow-1 text-info"></i> Back</a></router-link>
            </div>
          </div>
          <div class="card-body">
            <h3>Shipment not found! Please check the tracking number.</h3>
          </div>
        </div>
      </div>
    </div>
    <loading :active.sync="isLoading"></loading>
  </div>
</template>
<style>
.custom-margin-btn {
  margin-top: 2rem !important;
}
</style>

<script>
// import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import { mapGetters } from "vuex";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  data() {
    return {
      trackingNo: this.$route.params.trackingNo,
      isLoading: false,
      fullPage: true,
      currentStatus: "",
      currentStatusTime: "",
      shipment: {},
      IsLoaded: false,
      //   statusesDataList: [],
      //   locationDataList: [],
      //   remarksDataList: [],
      //   proddesclist: [],
      //   allcharges: [],
      //   zones: [],
      //   firstweightscale: null,
      //   firstsizescale: null,
      //   firstcurrency: null,
      //   events: [],
      //   input: null,
      totalcharges: 0.0,
      // shipmenttypelist: [{ name: "General" }, { name: "Food/Supp/Meds" }],
      // shippingmodelist: [
      //   { name: "Truck" },
      //   { name: "Air" },
      //   // { name: "Sea Express" },
      //   // { name: "Sea Consol" },
      // ],

      nonce: 0,
    };
  },
  filters: {
    CustomFormatDate(value) {
      const date =
        value.getFullYear() +
        "-" +
        (value.getMonth() + 1) +
        "-" +
        value.getDate();
      const time =
        value.getHours() + ":" + value.getMinutes() + ":" + value.getSeconds();
      const dateTime = date + " " + time;
      return dateTime;
    },
  },
  components: {
    Loading,
  },
  computed: {
    timeline() {
      return this.events.slice().reverse();
    },
    ...mapGetters(["currentUser"]),
  },
  mounted() {
    this.isLoading = true;
    this.getShipment();
    // this.getAllWeightScales();
    // this.getAllSizeScales();
    // this.getAllCurrencies();
    // this.getStatusDataList();
    // this.getLocationsDataList();
    // this.getRemarksDataList();
    // this.getAllprodDescription();
    // this.getCharges();
    // this.getAllShipmentTypes();
    // this.getAllShippingModes();
    // this.getAllZones();
  },
  methods: {
    getShipment() {
      ApiService.get("track-shipment", this.trackingNo)
        .then(({ data }) => {
          if (data.success) {
            this.shipment = data.shipment;
            // console.log(data.shipment);
            this.currentStatus = this.shipment.shipmentstatuses.length
              ? this.shipment.shipmentstatuses[0].status
              : null;
            this.currentStatusTime = this.shipment.shipmentstatuses.length
              ? new Date(
                this.shipment.shipmentstatuses[0].datetime
              ).toLocaleString("en-us", {
                weekday: "long",
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })
              : null;
          } else {
            this.makeToastVariant("danger", data.msg, "Error");
          }
          this.isLoading = false;
          this.IsLoaded = true;
          //   console.log(data.shipment);
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
    makeToastVariant(variant = null, text, title) {
      this.$bvToast.toast(text, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>
